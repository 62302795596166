'use client';

import { LinearProgress, Skeleton } from '@mui/material';

export default function TenantLoadingState() {
  return (
    <>
      <LinearProgress
        sx={{ position: 'absolute', top: 0, left: 0, width: '100vw' }}
      />
      <Skeleton sx={(theme) => ({ height: theme.spacing(6) })} />
    </>
  );
}
